import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { getApiEndpoint, isEDMode, getNested } from '@src/helper';

// Style Imports
import './styles.scss'
// SEO
import SEO from '@components/utility/SEO.jsx'
// Structural Imports
import Layout from "@components/layout/layout.jsx"


const UnsubscribeConfirmed = ({location}) => {
    
    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeTreatment: true,
                unsubscribeCorporate: true
            } 
        }
    }
    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {
            
            navigate('/unsubscribe');
        }
    },[])

    const resubscribe = (e) => {
        
        if(e.type === 'click' || e.code === 'Enter'){
            e.preventDefault()
            const unSubToken = location.state.token ? {token: location.state.token} : {}
            fetch(getApiEndpoint() + 'allinforln/resubscribe', {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(unSubToken),
            })
            .then(async (resp) => {
                var r = await resp.json();
                if (r.success) {
                    navigate('/resubscribe-confirmed',
                        {
                            state: {
                                values: location.state.values 
                            }  
                        }
                    );
                } else {
                    navigate('/500');
                }
            })
            .catch((err) => {
                navigate('/500');
            })
        }

    }

    return (
        <Layout addedClass="page--unsubscribe-confirmed">
            <SEO title="Unsubscribe Confirmation | ALL IN&reg; for Lupus Nephritis" description="" robots="noindex" canonicalURL="https://www.allinforlupusnephritis.com/unsubscribe-confirmed" />
            <section className="bg-white">
              <div className="container container--narrow subscribe-confirmation-container">
                    <h1 className="heading heading--accent">We miss you already!</h1> 
                    <h2>You've officially unsubscribed</h2> 

                    <p>You have been successfully unsubscribed from the selected communication channels.</p>
                    <p>We're always here for you. If you've changed your mind, you can <a tabIndex="0" onClick={resubscribe} onKeyDown={resubscribe}>resubscribe here</a>. You can always come back later to resubscribe.</p>

                    <Link to="/" className="cta--purple cta--body cta--body--wide">Visit the ALL IN Home Page</Link>
              </div>
            </section>
        </Layout>
    )
}

export default UnsubscribeConfirmed
